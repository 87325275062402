import config from '../config'
import authApi from './authApi'
import 'whatwg-fetch'

async function getLikes() {
	const token = await authApi.getToken()
	if (!token)
		return

	return fetch(`${config.likeService}/likes`, {
		method: 'GET',
		headers: {
			authorization: `Bearer ${token}`
		}
	})
	.then(res => res.json())
}

async function likePost(postId) {
	return fetch(`${config.likeService}/like/${postId}`, {
		method: 'PUT',
		headers: {
			authorization: `Bearer ${await authApi.getToken()}`
		}
	})
	.then(res => {
		if (res.status !== 201)
			throw {code: res.status, message: res.statusText}
	})
}

async function unlikePost(postId) {
	return fetch(`${config.likeService}/unlike/${postId}`, {
		method: 'PUT',
		headers: {
			authorization: `Bearer ${await authApi.getToken()}`
		}
	})
	.then(res => {
		if (res.status !== 201)
			throw {code: res.status, message: res.statusText}
	})
}

export default {getLikes, likePost, unlikePost}